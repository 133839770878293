/**
 * T&R Kpler service REST API
 * Trading & Risk Kpler service RESTful Application Programming Interface
 *
 * The version of the OpenAPI document: 2.3
 * Contact: gcb-rt@enverus.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type SplitOption =
    | 'buyer'
    | 'charterers'
    | 'crudeQuality'
    | 'destinationCountries'
    | 'destinationContinents'
    | 'destinationPadds'
    | 'destinationPorts'
    | 'destinationTradingRegions'
    | 'grades'
    | 'originCountries'
    | 'originContinents'
    | 'originPadds'
    | 'originPorts'
    | 'originTradingRegions'
    | 'products'
    | 'routes'
    | 'seller'
    | 'total'
    | 'vesselType';

export const SplitOption = {
    Buyer: 'buyer' as SplitOption,
    Charterers: 'charterers' as SplitOption,
    CrudeQuality: 'crudeQuality' as SplitOption,
    DestinationCountries: 'destinationCountries' as SplitOption,
    DestinationContinents: 'destinationContinents' as SplitOption,
    DestinationPadds: 'destinationPadds' as SplitOption,
    DestinationPorts: 'destinationPorts' as SplitOption,
    DestinationTradingRegions: 'destinationTradingRegions' as SplitOption,
    Grades: 'grades' as SplitOption,
    OriginCountries: 'originCountries' as SplitOption,
    OriginContinents: 'originContinents' as SplitOption,
    OriginPadds: 'originPadds' as SplitOption,
    OriginPorts: 'originPorts' as SplitOption,
    OriginTradingRegions: 'originTradingRegions' as SplitOption,
    Products: 'products' as SplitOption,
    Routes: 'routes' as SplitOption,
    Seller: 'seller' as SplitOption,
    Total: 'total' as SplitOption,
    VesselType: 'vesselType' as SplitOption
};
