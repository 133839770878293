import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function positiveIntegerRangeValidator(): ValidatorFn {
    const positiveIntegerPattern = /^(?!0)([1-9]|[12]\d|30|31)$/;

    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!positiveIntegerPattern.test(value)) {
            return { invalidNumber: true };
        }

        return null;
    };
}
