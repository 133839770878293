/**
 * T&R Kpler service REST API
 * Trading & Risk Kpler service RESTful Application Programming Interface
 *
 * The version of the OpenAPI document: 2.3
 * Contact: gcb-rt@enverus.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type YoyOption = 'preCovid' | 'covid' | 'postCovid' | 'excludeCovid' | 'all';

export const YoyOption = {
    PreCovid: 'preCovid' as YoyOption,
    Covid: 'covid' as YoyOption,
    PostCovid: 'postCovid' as YoyOption,
    ExcludeCovid: 'excludeCovid' as YoyOption,
    All: 'all' as YoyOption
};
