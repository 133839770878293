/**
 * T&R Kpler service REST API
 * Trading & Risk Kpler service RESTful Application Programming Interface
 *
 * The version of the OpenAPI document: 2.3
 * Contact: gcb-rt@enverus.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type TimePeriod = 'daily' | 'weekly' | 'eiaWeekly' | 'monthly' | 'quarterly' | 'biannual' | 'annually' | 'biennial' | 'triennial';

export const TimePeriod = {
    Daily: 'daily' as TimePeriod,
    Weekly: 'weekly' as TimePeriod,
    EiaWeekly: 'eiaWeekly' as TimePeriod,
    Monthly: 'monthly' as TimePeriod,
    Quarterly: 'quarterly' as TimePeriod,
    Biannual: 'biannual' as TimePeriod,
    Annually: 'annually' as TimePeriod,
    Biennial: 'biennial' as TimePeriod,
    Triennial: 'triennial' as TimePeriod
};
