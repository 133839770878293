import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { KplerAPIConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { InventoryControllerService } from './api/inventoryController.service';
import { PriceControllerService } from './api/priceController.service';
import { PriceSymbolControllerService } from './api/priceSymbolController.service';
import { ProductionForecastControllerService } from './api/productionForecastController.service';
import { PublicSnapshotControllerService } from './api/publicSnapshotController.service';
import { RouteControllerService } from './api/routeController.service';
import { SnapshotsControllerService } from './api/snapshotsController.service';
import { TradingFlowControllerService } from './api/tradingFlowController.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => KplerAPIConfiguration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [{ provide: KplerAPIConfiguration, useFactory: configurationFactory }]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error(
                'You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575'
            );
        }
    }
}
