import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ReadUser } from '@app/user-manager/shared/api/authorization/generated';
import { JobNameGeneratorService } from '@app/workflow/services/job-name-generator.service';
import { JobMetadataService, Workflow } from '@app/workflow/shared/api/workflow/generated';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyControlBuilderService } from '@shared/formly/formly-control-builder.service';
import {
    FileNameConfig,
    SpecializedEdmTypeKey,
    SpecializedSchemaField,
    SphereJSONSchema,
    SphereJSONSchemaArray,
    SphereJSONSchemaObject,
    SphereJSONSchemaString
} from '@shared/formly/formly-control-builder.models';
import { SftpSchema } from '@shared/formly/sftp-config/sftp-config.models';
import { map } from 'rxjs/operators';

export interface InputSchema {
    [key: string]: SphereJSONSchema;
}

export function minValidationMessage(err, field) {
    return `This value must be greater than or equal to ${field.templateOptions.min}.`;
}

export function maxValidationMessage(err, field) {
    return `This value must be less than or equal to ${field.templateOptions.max}.`;
}

@Injectable({
    providedIn: 'root'
})
export class JobInputFormService {
    public readonly MIN_VALUE_LENGTH: number = 3;
    public readonly MAX_VALUE_LENGTH: number = 60;

    constructor(
        private jobMetadata: JobMetadataService,
        private jobNameHelper: JobNameGeneratorService,
        private formlyControlBuilder: FormlyControlBuilderService
    ) {}

    buildFormlyFieldConfig(
        schema: SphereJSONSchemaObject,
        state: any,
        workflow: Workflow,
        requiredFields: string[],
        prefix: string = 'Task',
        config?: FileNameConfig,
        mode: 'create' | 'edit' = 'create',
        disableFields: string[] = []
    ): FormlyFieldConfig[] {
        const _prefix = prefix?.replace(' ', '_');
        const defaultName = state?.['name'] || state?.['name'] === '' ? state['name'] : this.jobNameHelper.uniqueJobName(_prefix),
            defaultDescription = state?.['description'] || '',
            defaultCategory = state?.['category'] || workflow?.category || '';
        const categoryFilterOptions = this.jobMetadata.apiWorkflowV1JobMetadataCategoriesGet().pipe(map(categories => categories.filter(c => !!c)));
        const fieldConfig: FormlyFieldConfig[] = [
            this.formlyControlBuilder.buildNameControl('name', defaultName, 'task-config__field'),
            this.formlyControlBuilder.buildDescriptionControl('description', defaultDescription, 'task-config__field'),
            this.formlyControlBuilder.buildCategoryControl('category', defaultCategory, categoryFilterOptions, 'task-config__field')
        ];

        /**
         * Push advanced settings to the end of the array.
         */
        const fields = Object.entries(schema).filter(([key, fieldSchema]) => {
            return !fieldSchema.advancedSetting;
        });

        for (const [key, fieldSchema] of fields) {
            const field = this.buildFormlyFieldsForSchema(key, fieldSchema, requiredFields, state, config, mode);
            if (field) {
                fieldConfig.push(field);
            }
        }
        if (disableFields && disableFields.length > 0) {
            for (const field of fieldConfig) {
                if (disableFields.includes(field.key as any)) {
                    field.props = {
                        ...field.props,
                        disabled: true
                    };
                }
            }
        }
        return fieldConfig;
    }

    buildAdvancedFormlyFieldConfig(
        schema: SphereJSONSchemaObject,
        state: any,
        workflow: Workflow,
        requiredFields: string[],
        prefix: string = 'Task',
        config?: FileNameConfig,
        mode: 'create' | 'edit' = 'create',
        getNameFn?: () => string,
        getCategoryFn?: () => string
    ): FormlyFieldConfig[] {
        const defaultUserId = state?.['userId'] || null,
            defaultTriggerPattern = state?.['triggerPattern'] || null;
        const advancedFieldConfig: FormlyFieldConfig[] = [
            // TODO:2.9.0 Trigger pattern (triggerPattern) field is hidden as bespoke UI is not implemented yet.
            // this.formlyControlBuilder.buildTriggerPatternControl('triggerPattern', defaultTriggerPattern, 'task-config__field'),
            this.formlyControlBuilder.buildRunAsUserControl('userId', defaultUserId, ReadUser.TypeEnum.Technical, 'task-config__field')
        ];
        const fields = Object.entries(schema).filter(([key, fieldSchema]) => {
            return !!fieldSchema.advancedSetting;
        });
        for (const [key, fieldSchema] of fields) {
            advancedFieldConfig.push(
                this.buildFormlyFieldsForSchema(key, fieldSchema, requiredFields, state, config, mode, getNameFn, getCategoryFn)
            );
        }
        return advancedFieldConfig;
    }

    private buildFormlyFieldsForSchema(
        key: string,
        fieldSchema: SphereJSONSchema,
        requiredFields: string[],
        state: any,
        config?: FileNameConfig,
        mode: 'create' | 'edit' = 'create',
        getNameFn?: () => string,
        getCategoryFn?: () => string
    ): FormlyFieldConfig {
        const _value = state?.[key];
        const { default: defaultValue, format, edmType, advancedSetting } = fieldSchema;

        if (key === SpecializedSchemaField.FileName) {
            return this.formlyControlBuilder.buildFileNameControl(
                key,
                requiredFields,
                fieldSchema as SphereJSONSchemaString,
                config,
                _value,
                'task-config__field',
                getNameFn,
                getCategoryFn
            );
        } else if (key === SpecializedSchemaField.FilePrefix) {
            // FilePrefix is only shown if in edit mode and if already has a value.
            if (mode === 'edit' && !!state?.[SpecializedSchemaField.FilePrefix]) {
                return this.formlyControlBuilder.buildFilePrefixControl(key, requiredFields, _value, 'task-config__field');
            }
        } else if (edmType === SpecializedEdmTypeKey.SftpConfig) {
            return this.formlyControlBuilder.buildSftpControl(key, requiredFields, fieldSchema as unknown as SftpSchema, _value);
        } else if (edmType === SpecializedEdmTypeKey.Calendar) {
            return this.formlyControlBuilder.buildCalendarSelector(
                key,
                requiredFields,
                fieldSchema as SphereJSONSchemaString | SphereJSONSchemaArray,
                _value,
                'task-config__field'
            );
        } else if (this.formlyControlBuilder.isDateControlEdmType(edmType) || this.formlyControlBuilder.isDateControlFormat(format)) {
            return this.formlyControlBuilder.buildDateControl(
                key,
                requiredFields,
                fieldSchema as SphereJSONSchemaString,
                _value,
                'task-config__datepicker'
            );
        } else if (fieldSchema['edmType']) {
            return this.formlyControlBuilder.buildEdmTypeControl(key, requiredFields, fieldSchema, state[key], 'task-config__field');
        } else if (this.formlyControlBuilder.sswfIncludeInDynamicForm(key, edmType as SpecializedEdmTypeKey)) {
            const _fieldSchema = {
                ...fieldSchema,
                defaultValue: mode === 'create' ? defaultValue : undefined,
                default: mode === 'create' ? defaultValue : undefined
            };
            return this.formlyControlBuilder.buildGenericControl(key, requiredFields, _fieldSchema, _value);
        }
    }

    isStartCharacterValidValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const invalidMatcher = /^\S/;
            const valid = invalidMatcher.test(control.value);
            return valid
                ? null
                : {
                      invalidStartCharacter: 'The first character must be a letter.'
                  };
        };
    }

    isNameValidValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const invalidMatcher = /^[a-zA-Z0-9_\-\s]*$/;
            const valid = invalidMatcher.test(control.value);
            return valid
                ? null
                : {
                      invalidCharacter: 'You may only use letters, numbers or "_ -" symbols.'
                  };
        };
    }
}
