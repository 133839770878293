/**
 * T&R Kpler service REST API
 * Trading & Risk Kpler service RESTful Application Programming Interface
 *
 * The version of the OpenAPI document: 2.3
 * Contact: gcb-rt@enverus.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type FlowUnit = 'thousandsOfBarrelsPerDay' | 'barrels' | 'metricTons' | 'cubicMetres';

export const FlowUnit = {
    ThousandsOfBarrelsPerDay: 'thousandsOfBarrelsPerDay' as FlowUnit,
    Barrels: 'barrels' as FlowUnit,
    MetricTons: 'metricTons' as FlowUnit,
    CubicMetres: 'cubicMetres' as FlowUnit
};
