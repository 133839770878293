/**
 * T&R Kpler service REST API
 * Trading & Risk Kpler service RESTful Application Programming Interface
 *
 * The version of the OpenAPI document: 2.3
 * Contact: gcb-rt@enverus.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { FlowDTO } from '../model/models';
import { FlowDirection } from '../model/models';
import { FlowUnit } from '../model/models';
import { LocationGrouping } from '../model/models';
import { TimePeriod } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { KplerAPIConfiguration } from '../configuration';
import { RouteControllerServiceInterface } from './routeController.serviceInterface';

@Injectable({
    providedIn: 'root'
})
export class RouteControllerService implements RouteControllerServiceInterface {
    protected basePath = 'http://localhost/api/kpler';
    public defaultHeaders = new HttpHeaders();
    public configuration = new KplerAPIConfiguration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: KplerAPIConfiguration
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                    throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * Get top locations
     * @param products Comma separated list of names of Products to consider
     * @param grades Comma separated list of grades
     * @param flowDirection Flow direction
     * @param timePeriod Type of Aggregation
     * @param unit Unit
     * @param groupLocationsBy Location grouping strategy
     * @param anomalyThreshold Threshold to tag the change as an anomaly
     * @param precision Number of decimal digits
     * @param limit Maximum number of results
     * @param date Date to identify the reference period. Response will contain the reference period, the previous, one before the previous and the year on year period. Splits will get sorted using the order given by the reference period
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTopLocations(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        groupLocationsBy: LocationGrouping,
        anomalyThreshold: number,
        precision: number,
        limit: number,
        date?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<FlowDTO>>;
    public getTopLocations(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        groupLocationsBy: LocationGrouping,
        anomalyThreshold: number,
        precision: number,
        limit: number,
        date?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<FlowDTO>>>;
    public getTopLocations(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        groupLocationsBy: LocationGrouping,
        anomalyThreshold: number,
        precision: number,
        limit: number,
        date?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<FlowDTO>>>;
    public getTopLocations(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        groupLocationsBy: LocationGrouping,
        anomalyThreshold: number,
        precision: number,
        limit: number,
        date?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        if (products === null || products === undefined) {
            throw new Error('Required parameter products was null or undefined when calling getTopLocations.');
        }
        if (grades === null || grades === undefined) {
            throw new Error('Required parameter grades was null or undefined when calling getTopLocations.');
        }
        if (flowDirection === null || flowDirection === undefined) {
            throw new Error('Required parameter flowDirection was null or undefined when calling getTopLocations.');
        }
        if (timePeriod === null || timePeriod === undefined) {
            throw new Error('Required parameter timePeriod was null or undefined when calling getTopLocations.');
        }
        if (unit === null || unit === undefined) {
            throw new Error('Required parameter unit was null or undefined when calling getTopLocations.');
        }
        if (groupLocationsBy === null || groupLocationsBy === undefined) {
            throw new Error('Required parameter groupLocationsBy was null or undefined when calling getTopLocations.');
        }
        if (anomalyThreshold === null || anomalyThreshold === undefined) {
            throw new Error('Required parameter anomalyThreshold was null or undefined when calling getTopLocations.');
        }
        if (precision === null || precision === undefined) {
            throw new Error('Required parameter precision was null or undefined when calling getTopLocations.');
        }
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling getTopLocations.');
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (products) {
            products.forEach(element => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'products');
            });
        }
        if (grades) {
            grades.forEach(element => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'grades');
            });
        }
        if (flowDirection !== undefined && flowDirection !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>flowDirection, 'flowDirection');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>timePeriod, 'timePeriod');
        }
        if (unit !== undefined && unit !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>unit, 'unit');
        }
        if (date !== undefined && date !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>date, 'date');
        }
        if (groupLocationsBy !== undefined && groupLocationsBy !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>groupLocationsBy, 'groupLocationsBy');
        }
        if (anomalyThreshold !== undefined && anomalyThreshold !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>anomalyThreshold, 'anomalyThreshold');
        }
        if (precision !== undefined && precision !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>precision, 'precision');
        }
        if (limit !== undefined && limit !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FlowDTO>>(`${this.configuration.basePath}/trading-routes/top-locations`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get top locations
     * @param products Comma separated list of names of Products to consider
     * @param grades Comma separated list of grades
     * @param flowDirection Flow direction
     * @param timePeriod Type of Aggregation
     * @param unit Unit
     * @param groupOriginBy Origin locations grouping strategy
     * @param groupDestinationBy Destination locations grouping strategy
     * @param anomalyThreshold Threshold to tag the change as an anomaly
     * @param precision Number of decimal digits
     * @param limit Maximum number of results
     * @param date Date to identify the reference period. Response will contain the reference period, the previous, one before the previous and the year on year period. Splits will get sorted using the order given by the reference period
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTopRoutes(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        groupOriginBy: LocationGrouping,
        groupDestinationBy: LocationGrouping,
        anomalyThreshold: number,
        precision: number,
        limit: number,
        date?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<FlowDTO>>;
    public getTopRoutes(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        groupOriginBy: LocationGrouping,
        groupDestinationBy: LocationGrouping,
        anomalyThreshold: number,
        precision: number,
        limit: number,
        date?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<FlowDTO>>>;
    public getTopRoutes(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        groupOriginBy: LocationGrouping,
        groupDestinationBy: LocationGrouping,
        anomalyThreshold: number,
        precision: number,
        limit: number,
        date?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<FlowDTO>>>;
    public getTopRoutes(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        groupOriginBy: LocationGrouping,
        groupDestinationBy: LocationGrouping,
        anomalyThreshold: number,
        precision: number,
        limit: number,
        date?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        if (products === null || products === undefined) {
            throw new Error('Required parameter products was null or undefined when calling getTopRoutes.');
        }
        if (grades === null || grades === undefined) {
            throw new Error('Required parameter grades was null or undefined when calling getTopRoutes.');
        }
        if (flowDirection === null || flowDirection === undefined) {
            throw new Error('Required parameter flowDirection was null or undefined when calling getTopRoutes.');
        }
        if (timePeriod === null || timePeriod === undefined) {
            throw new Error('Required parameter timePeriod was null or undefined when calling getTopRoutes.');
        }
        if (unit === null || unit === undefined) {
            throw new Error('Required parameter unit was null or undefined when calling getTopRoutes.');
        }
        if (groupOriginBy === null || groupOriginBy === undefined) {
            throw new Error('Required parameter groupOriginBy was null or undefined when calling getTopRoutes.');
        }
        if (groupDestinationBy === null || groupDestinationBy === undefined) {
            throw new Error('Required parameter groupDestinationBy was null or undefined when calling getTopRoutes.');
        }
        if (anomalyThreshold === null || anomalyThreshold === undefined) {
            throw new Error('Required parameter anomalyThreshold was null or undefined when calling getTopRoutes.');
        }
        if (precision === null || precision === undefined) {
            throw new Error('Required parameter precision was null or undefined when calling getTopRoutes.');
        }
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling getTopRoutes.');
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (products) {
            products.forEach(element => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'products');
            });
        }
        if (grades) {
            grades.forEach(element => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'grades');
            });
        }
        if (flowDirection !== undefined && flowDirection !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>flowDirection, 'flowDirection');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>timePeriod, 'timePeriod');
        }
        if (unit !== undefined && unit !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>unit, 'unit');
        }
        if (date !== undefined && date !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>date, 'date');
        }
        if (groupOriginBy !== undefined && groupOriginBy !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>groupOriginBy, 'groupOriginBy');
        }
        if (groupDestinationBy !== undefined && groupDestinationBy !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>groupDestinationBy, 'groupDestinationBy');
        }
        if (anomalyThreshold !== undefined && anomalyThreshold !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>anomalyThreshold, 'anomalyThreshold');
        }
        if (precision !== undefined && precision !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>precision, 'precision');
        }
        if (limit !== undefined && limit !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FlowDTO>>(`${this.configuration.basePath}/trading-routes/top-routes`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get top locations
     * @param products Comma separated list of names of Products to consider
     * @param grades Comma separated list of grades
     * @param flowDirection Flow direction
     * @param timePeriod Type of Aggregation
     * @param unit Unit
     * @param groupOriginBy Origin locations grouping strategy
     * @param groupDestinationBy Destination locations grouping strategy
     * @param anomalyThreshold Threshold to tag the change as an anomaly
     * @param precision Number of decimal digits
     * @param limit Maximum number of results
     * @param origin Location of origin
     * @param date Date to identify the reference period. Response will contain the reference period, the previous, one before the previous and the year on year period. Splits will get sorted using the order given by the reference period
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTopRoutesByOrigin(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        groupOriginBy: LocationGrouping,
        groupDestinationBy: LocationGrouping,
        anomalyThreshold: number,
        precision: number,
        limit: number,
        origin: string,
        date?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<FlowDTO>>;
    public getTopRoutesByOrigin(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        groupOriginBy: LocationGrouping,
        groupDestinationBy: LocationGrouping,
        anomalyThreshold: number,
        precision: number,
        limit: number,
        origin: string,
        date?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<FlowDTO>>>;
    public getTopRoutesByOrigin(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        groupOriginBy: LocationGrouping,
        groupDestinationBy: LocationGrouping,
        anomalyThreshold: number,
        precision: number,
        limit: number,
        origin: string,
        date?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<FlowDTO>>>;
    public getTopRoutesByOrigin(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        groupOriginBy: LocationGrouping,
        groupDestinationBy: LocationGrouping,
        anomalyThreshold: number,
        precision: number,
        limit: number,
        origin: string,
        date?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        if (products === null || products === undefined) {
            throw new Error('Required parameter products was null or undefined when calling getTopRoutesByOrigin.');
        }
        if (grades === null || grades === undefined) {
            throw new Error('Required parameter grades was null or undefined when calling getTopRoutesByOrigin.');
        }
        if (flowDirection === null || flowDirection === undefined) {
            throw new Error('Required parameter flowDirection was null or undefined when calling getTopRoutesByOrigin.');
        }
        if (timePeriod === null || timePeriod === undefined) {
            throw new Error('Required parameter timePeriod was null or undefined when calling getTopRoutesByOrigin.');
        }
        if (unit === null || unit === undefined) {
            throw new Error('Required parameter unit was null or undefined when calling getTopRoutesByOrigin.');
        }
        if (groupOriginBy === null || groupOriginBy === undefined) {
            throw new Error('Required parameter groupOriginBy was null or undefined when calling getTopRoutesByOrigin.');
        }
        if (groupDestinationBy === null || groupDestinationBy === undefined) {
            throw new Error('Required parameter groupDestinationBy was null or undefined when calling getTopRoutesByOrigin.');
        }
        if (anomalyThreshold === null || anomalyThreshold === undefined) {
            throw new Error('Required parameter anomalyThreshold was null or undefined when calling getTopRoutesByOrigin.');
        }
        if (precision === null || precision === undefined) {
            throw new Error('Required parameter precision was null or undefined when calling getTopRoutesByOrigin.');
        }
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling getTopRoutesByOrigin.');
        }
        if (origin === null || origin === undefined) {
            throw new Error('Required parameter origin was null or undefined when calling getTopRoutesByOrigin.');
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (products) {
            products.forEach(element => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'products');
            });
        }
        if (grades) {
            grades.forEach(element => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'grades');
            });
        }
        if (flowDirection !== undefined && flowDirection !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>flowDirection, 'flowDirection');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>timePeriod, 'timePeriod');
        }
        if (unit !== undefined && unit !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>unit, 'unit');
        }
        if (date !== undefined && date !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>date, 'date');
        }
        if (groupOriginBy !== undefined && groupOriginBy !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>groupOriginBy, 'groupOriginBy');
        }
        if (groupDestinationBy !== undefined && groupDestinationBy !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>groupDestinationBy, 'groupDestinationBy');
        }
        if (anomalyThreshold !== undefined && anomalyThreshold !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>anomalyThreshold, 'anomalyThreshold');
        }
        if (precision !== undefined && precision !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>precision, 'precision');
        }
        if (limit !== undefined && limit !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>limit, 'limit');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FlowDTO>>(`${this.configuration.basePath}/trading-routes/top-routes/${encodeURIComponent(String(origin))}`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get totals
     * @param products Comma separated list of names of Products to consider
     * @param grades Comma separated list of grades
     * @param flowDirection Flow direction
     * @param timePeriod Type of Aggregation
     * @param unit Unit
     * @param precision Number of decimal digits
     * @param date Date to identify the reference period. Response will contain the reference period, the previous, one before the previous and the year on year period. Splits will get sorted using the order given by the reference period
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTotalFlows(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        precision: number,
        date?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<FlowDTO>>;
    public getTotalFlows(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        precision: number,
        date?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<FlowDTO>>>;
    public getTotalFlows(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        precision: number,
        date?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<FlowDTO>>>;
    public getTotalFlows(
        products: Array<string>,
        grades: Array<string>,
        flowDirection: FlowDirection,
        timePeriod: TimePeriod,
        unit: FlowUnit,
        precision: number,
        date?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        if (products === null || products === undefined) {
            throw new Error('Required parameter products was null or undefined when calling getTotalFlows.');
        }
        if (grades === null || grades === undefined) {
            throw new Error('Required parameter grades was null or undefined when calling getTotalFlows.');
        }
        if (flowDirection === null || flowDirection === undefined) {
            throw new Error('Required parameter flowDirection was null or undefined when calling getTotalFlows.');
        }
        if (timePeriod === null || timePeriod === undefined) {
            throw new Error('Required parameter timePeriod was null or undefined when calling getTotalFlows.');
        }
        if (unit === null || unit === undefined) {
            throw new Error('Required parameter unit was null or undefined when calling getTotalFlows.');
        }
        if (precision === null || precision === undefined) {
            throw new Error('Required parameter precision was null or undefined when calling getTotalFlows.');
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (products) {
            products.forEach(element => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'products');
            });
        }
        if (grades) {
            grades.forEach(element => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'grades');
            });
        }
        if (flowDirection !== undefined && flowDirection !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>flowDirection, 'flowDirection');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>timePeriod, 'timePeriod');
        }
        if (unit !== undefined && unit !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>unit, 'unit');
        }
        if (date !== undefined && date !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>date, 'date');
        }
        if (precision !== undefined && precision !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>precision, 'precision');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FlowDTO>>(`${this.configuration.basePath}/trading-routes/totals`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }
}
