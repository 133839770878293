/**
 * T&R Kpler service REST API
 * Trading & Risk Kpler service RESTful Application Programming Interface
 *
 * The version of the OpenAPI document: 2.3
 * Contact: gcb-rt@enverus.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { FlowDTO } from '../model/models';
import { TimePeriod } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { KplerAPIConfiguration } from '../configuration';
import { ProductionForecastControllerServiceInterface } from './productionForecastController.serviceInterface';

@Injectable({
    providedIn: 'root'
})
export class ProductionForecastControllerService implements ProductionForecastControllerServiceInterface {
    protected basePath = 'http://localhost/api/kpler';
    public defaultHeaders = new HttpHeaders();
    public configuration = new KplerAPIConfiguration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: KplerAPIConfiguration
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                    throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * Add a new forecast
     * @param precision Number of decimal digits
     * @param file
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addForecast(
        precision: number,
        file: Blob,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
    ): Observable<Array<FlowDTO>>;
    public addForecast(
        precision: number,
        file: Blob,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
    ): Observable<HttpResponse<Array<FlowDTO>>>;
    public addForecast(
        precision: number,
        file: Blob,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
    ): Observable<HttpEvent<Array<FlowDTO>>>;
    public addForecast(
        precision: number,
        file: Blob,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
    ): Observable<any> {
        if (precision === null || precision === undefined) {
            throw new Error('Required parameter precision was null or undefined when calling addForecast.');
        }
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling addForecast.');
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (precision !== undefined && precision !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>precision, 'precision');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['application/json'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = ['multipart/form-data'];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({ encoder: this.encoder });
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Array<FlowDTO>>(
            `${this.configuration.basePath}/production-forecast`,
            localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get latest forecast
     * @param product Name of the product
     * @param timePeriod Type of aggregation
     * @param anomalyThreshold Threshold to tag the change as an anomaly
     * @param precision Number of decimal digits
     * @param date Date to identify the reference period. Response will contain the reference period, the next, one after the next and the next year on year period. Splits will get sorted using the order given by the reference period
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLatestForecast(
        product: string,
        timePeriod: TimePeriod,
        anomalyThreshold: number,
        precision: number,
        date?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<FlowDTO>>;
    public getLatestForecast(
        product: string,
        timePeriod: TimePeriod,
        anomalyThreshold: number,
        precision: number,
        date?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<FlowDTO>>>;
    public getLatestForecast(
        product: string,
        timePeriod: TimePeriod,
        anomalyThreshold: number,
        precision: number,
        date?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<FlowDTO>>>;
    public getLatestForecast(
        product: string,
        timePeriod: TimePeriod,
        anomalyThreshold: number,
        precision: number,
        date?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling getLatestForecast.');
        }
        if (timePeriod === null || timePeriod === undefined) {
            throw new Error('Required parameter timePeriod was null or undefined when calling getLatestForecast.');
        }
        if (anomalyThreshold === null || anomalyThreshold === undefined) {
            throw new Error('Required parameter anomalyThreshold was null or undefined when calling getLatestForecast.');
        }
        if (precision === null || precision === undefined) {
            throw new Error('Required parameter precision was null or undefined when calling getLatestForecast.');
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (product !== undefined && product !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>product, 'product');
        }
        if (timePeriod !== undefined && timePeriod !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>timePeriod, 'timePeriod');
        }
        if (date !== undefined && date !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>date, 'date');
        }
        if (anomalyThreshold !== undefined && anomalyThreshold !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>anomalyThreshold, 'anomalyThreshold');
        }
        if (precision !== undefined && precision !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>precision, 'precision');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<FlowDTO>>(`${this.configuration.basePath}/production-forecast`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }
}
