/**
 * T&R Kpler service REST API
 * Trading & Risk Kpler service RESTful Application Programming Interface
 *
 * The version of the OpenAPI document: 2.3
 * Contact: gcb-rt@enverus.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { SnapshotDetailDTO } from '../model/models';
import { SnapshotSummaryDTO } from '../model/models';
import { TimeFrame } from '../model/models';
import { TimePeriod } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { KplerAPIConfiguration } from '../configuration';
import { SnapshotsControllerServiceInterface } from './snapshotsController.serviceInterface';

@Injectable({
    providedIn: 'root'
})
export class SnapshotsControllerService implements SnapshotsControllerServiceInterface {
    protected basePath = 'http://localhost/api/kpler';
    public defaultHeaders = new HttpHeaders();
    public configuration = new KplerAPIConfiguration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: KplerAPIConfiguration
    ) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === 'object' && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                    throw Error('key may not be null if value is Date');
                }
            } else {
                Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error('key may not be null if value is not object or array');
        }
        return httpParams;
    }

    /**
     * Get Aggregated Flows Detailed Report
     * @param granularity Granularity
     * @param decimals Number of decimal digits
     * @param commodityGroup Commodity Group
     * @param productFamily Product Family
     * @param startDate Start of the period to consider, the end of the period is calculated using the granularity
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAggregatedFlowsDetails1(
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<SnapshotDetailDTO>>;
    public getAggregatedFlowsDetails1(
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<SnapshotDetailDTO>>>;
    public getAggregatedFlowsDetails1(
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<SnapshotDetailDTO>>>;
    public getAggregatedFlowsDetails1(
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        if (granularity === null || granularity === undefined) {
            throw new Error('Required parameter granularity was null or undefined when calling getAggregatedFlowsDetails1.');
        }
        if (decimals === null || decimals === undefined) {
            throw new Error('Required parameter decimals was null or undefined when calling getAggregatedFlowsDetails1.');
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (granularity !== undefined && granularity !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>granularity, 'granularity');
        }
        if (commodityGroup !== undefined && commodityGroup !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>commodityGroup, 'commodityGroup');
        }
        if (productFamily !== undefined && productFamily !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>productFamily, 'productFamily');
        }
        if (startDate !== undefined && startDate !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'startDate');
        }
        if (decimals !== undefined && decimals !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>decimals, 'decimals');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<SnapshotDetailDTO>>(`${this.configuration.basePath}/snapshot/aggFlowsDetail`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get Aggregated Flows Summary Report for commodity and granularity
     * @param timeFrame
     * @param granularity Granularity
     * @param decimals Number of decimal digits
     * @param commodityGroup Commodity Group
     * @param productFamily Product Family
     * @param startDate Start of the period to consider (ISO format), default obtained applying the time frame to the end date
     * @param endDate End of the period to consider (ISO format), defaults to the current date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAggregatedFlowsSummary1(
        timeFrame: TimeFrame,
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        endDate?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<SnapshotSummaryDTO>>;
    public getAggregatedFlowsSummary1(
        timeFrame: TimeFrame,
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        endDate?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<SnapshotSummaryDTO>>>;
    public getAggregatedFlowsSummary1(
        timeFrame: TimeFrame,
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        endDate?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<SnapshotSummaryDTO>>>;
    public getAggregatedFlowsSummary1(
        timeFrame: TimeFrame,
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        endDate?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        if (timeFrame === null || timeFrame === undefined) {
            throw new Error('Required parameter timeFrame was null or undefined when calling getAggregatedFlowsSummary1.');
        }
        if (granularity === null || granularity === undefined) {
            throw new Error('Required parameter granularity was null or undefined when calling getAggregatedFlowsSummary1.');
        }
        if (decimals === null || decimals === undefined) {
            throw new Error('Required parameter decimals was null or undefined when calling getAggregatedFlowsSummary1.');
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (commodityGroup !== undefined && commodityGroup !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>commodityGroup, 'commodityGroup');
        }
        if (productFamily !== undefined && productFamily !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>productFamily, 'productFamily');
        }
        if (startDate !== undefined && startDate !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'endDate');
        }
        if (timeFrame !== undefined && timeFrame !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>timeFrame, 'timeFrame');
        }
        if (granularity !== undefined && granularity !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>granularity, 'granularity');
        }
        if (decimals !== undefined && decimals !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>decimals, 'decimals');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<SnapshotSummaryDTO>>(`${this.configuration.basePath}/snapshot/aggFlowsSummary`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get Inventories Detailed Report
     * @param granularity
     * @param decimals Number of decimal digits
     * @param commodityGroup Commodity Group
     * @param productFamily Product Family
     * @param startDate Start of the period to consider, the end of the period is calculated using the granularity.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInventoriesDetails1(
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<SnapshotDetailDTO>>;
    public getInventoriesDetails1(
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<SnapshotDetailDTO>>>;
    public getInventoriesDetails1(
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<SnapshotDetailDTO>>>;
    public getInventoriesDetails1(
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        if (granularity === null || granularity === undefined) {
            throw new Error('Required parameter granularity was null or undefined when calling getInventoriesDetails1.');
        }
        if (decimals === null || decimals === undefined) {
            throw new Error('Required parameter decimals was null or undefined when calling getInventoriesDetails1.');
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (granularity !== undefined && granularity !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>granularity, 'granularity');
        }
        if (commodityGroup !== undefined && commodityGroup !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>commodityGroup, 'commodityGroup');
        }
        if (productFamily !== undefined && productFamily !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>productFamily, 'productFamily');
        }
        if (startDate !== undefined && startDate !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'startDate');
        }
        if (decimals !== undefined && decimals !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>decimals, 'decimals');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<SnapshotDetailDTO>>(`${this.configuration.basePath}/snapshot/inventoriesDetail`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }

    /**
     * Get Inventories Summary Report for commodity and granularity
     * @param timeFrame
     * @param granularity Granularity
     * @param decimals Number of decimal digits
     * @param commodityGroup Commodity Group
     * @param productFamily Product Family
     * @param startDate Start of the period to consider (ISO format), default obtained applying the time frame to the end date
     * @param endDate End of the period to consider (ISO format), defaults to the current date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInventoriesSummary1(
        timeFrame: TimeFrame,
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        endDate?: string,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<Array<SnapshotSummaryDTO>>;
    public getInventoriesSummary1(
        timeFrame: TimeFrame,
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        endDate?: string,
        observe?: 'response',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpResponse<Array<SnapshotSummaryDTO>>>;
    public getInventoriesSummary1(
        timeFrame: TimeFrame,
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        endDate?: string,
        observe?: 'events',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<HttpEvent<Array<SnapshotSummaryDTO>>>;
    public getInventoriesSummary1(
        timeFrame: TimeFrame,
        granularity: TimePeriod,
        decimals: number,
        commodityGroup?: string,
        productFamily?: string,
        startDate?: string,
        endDate?: string,
        observe: any = 'body',
        reportProgress: boolean = false,
        options?: { httpHeaderAccept?: '*/*'; context?: HttpContext }
    ): Observable<any> {
        if (timeFrame === null || timeFrame === undefined) {
            throw new Error('Required parameter timeFrame was null or undefined when calling getInventoriesSummary1.');
        }
        if (granularity === null || granularity === undefined) {
            throw new Error('Required parameter granularity was null or undefined when calling getInventoriesSummary1.');
        }
        if (decimals === null || decimals === undefined) {
            throw new Error('Required parameter decimals was null or undefined when calling getInventoriesSummary1.');
        }

        let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
        if (commodityGroup !== undefined && commodityGroup !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>commodityGroup, 'commodityGroup');
        }
        if (productFamily !== undefined && productFamily !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>productFamily, 'productFamily');
        }
        if (startDate !== undefined && startDate !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'endDate');
        }
        if (timeFrame !== undefined && timeFrame !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>timeFrame, 'timeFrame');
        }
        if (granularity !== undefined && granularity !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>granularity, 'granularity');
        }
        if (decimals !== undefined && decimals !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>decimals, 'decimals');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth) required
        localVarCredential = this.configuration.lookupCredential('oauth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = ['*/*'];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let responseType_: 'text' | 'json' = 'json';
        if (localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<SnapshotSummaryDTO>>(`${this.configuration.basePath}/snapshot/inventoriesSummary`, {
            context: localVarHttpContext,
            params: localVarQueryParameters,
            responseType: <any>responseType_,
            withCredentials: this.configuration.withCredentials,
            headers: localVarHeaders,
            observe: observe,
            reportProgress: reportProgress
        });
    }
}
