/**
 * T&R Kpler service REST API
 * Trading & Risk Kpler service RESTful Application Programming Interface
 *
 * The version of the OpenAPI document: 2.3
 * Contact: gcb-rt@enverus.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type FlowDirection = 'Import' | 'Export';

export const FlowDirection = {
    Import: 'Import' as FlowDirection,
    Export: 'Export' as FlowDirection
};
