<section class="file-name-generator" [ngClass]="{ 'file-name-generator__with-error': !isFileNameMatched() }">
    <h3 class="file-name__title">File name configuration</h3>
    <p class="file-name__subtitle">Customize the file naming</p>
    <ng-container [formGroup]="FILE_NAME_FORM">
        @if (!isFileNameMatched()) {
            <sphere-alert severity="warning">
                <p>Your file name configuration was incorrect. Please review to ensure proper file naming.</p>
            </sphere-alert>
        }

        <mat-slide-toggle color="primary" formControlName="TaskName">Task name</mat-slide-toggle>
        <mat-slide-toggle color="primary" formControlName="TaskCategory">Category</mat-slide-toggle>
        <div class="file-name__date-settings">
            <mat-slide-toggle
                class="date"
                color="primary"
                (change)="isDateSelectedControl.setValue(!isDateSelectedControl.value)"
                [checked]="isDateSelectedControl.value"
                >Date
            </mat-slide-toggle>
            <ng-container *ngIf="isDateSelectedControl.value">
                <div class="file-name__checkboxes">
                    <mat-form-field>
                        <mat-label>D or B</mat-label>
                        <mat-select formControlName="DayType">
                            <mat-option *ngFor="let item of DAY_TYPE_OPTIONS" [value]="item.value">{{ item.viewValue }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>+ or -</mat-label>
                        <mat-select formControlName="ShiftType">
                            <mat-option *ngFor="let item of SHIFT_TYPE_OPTIONS" [value]="item.value">{{ item.viewValue }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="isShiftNumberVisible">
                        <mat-label>{{ dayTypeViewValue }}(s)</mat-label>
                        <input matInput type="number" formControlName="ShiftNumber" />
                        <mat-error *ngIf="shiftNumberControl.hasError('invalidNumber')"
                            >Value must be a positive integer between 1 and 31, both included.</mat-error
                        >
                    </mat-form-field>
                </div>

                <formly-form [fields]="FORMLY_FIELD_CONFIG" [form]="FILE_NAME_FORM"></formly-form>
            </ng-container>
        </div>
        <div class="file-name__file-extension-settings">
            <mat-slide-toggle class="file-extension" color="primary" [formControl]="isFileExtensionEnabledControl"
                >Custom file extension</mat-slide-toggle
            >
            <ng-container *ngIf="isFileExtensionEnabledControl.value">
                <mat-form-field>
                    <mat-label>File extension</mat-label>
                    <input matInput [formControl]="fileExtensionControl" />
                    <mat-hint>File extensions must start with a dot, and only include letters and numbers.</mat-hint>
                    <mat-error *ngIf="fileExtensionControl.hasError('pattern')">Invalid file extension.</mat-error>
                </mat-form-field>
            </ng-container>
        </div>
        <ng-container *ngIf="fileNamePreview">
            <p class="file-name__preview-label">Preview</p>
            <div class="file-name__preview">
                <p>{{ fileNamePreview }}</p>
            </div>
        </ng-container>
    </ng-container>
</section>
