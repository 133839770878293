import { Injectable } from '@angular/core';
import { format } from 'date-fns';

@Injectable({
    providedIn: 'root'
})
export class JobNameGeneratorService {
    public uniqueJobName(prefix: string) {
        const now = new Date();
        return `${prefix}_${format(now, `yyyy-MM-dd_HH-mm-SS`)}`;
    }

    public uniqueFilePrefix(prefix: string) {
        const now = new Date();
        return `${prefix}_${format(now, `yyyyMMdd_HHmmSS`)}`;
    }
}
