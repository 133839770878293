import { Injectable } from '@angular/core';
import { SphereDateService } from '@app/portal/settings/helpers/sphere-date.service';
import { SettingsService } from '@curve-builder/shared/api/formula/generated';
import { format } from 'date-fns';

export interface TaskNameTokenConfig {
    hasTaskName: boolean;
    hasTaskCategory: boolean;
    hasDateFormat: boolean;

    dateFormat: string;
    dayType: string;
    shiftType: string;
    shiftNumber: number;

    fileExtension?: string;

    isFileNameMatched?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class FileNameGeneratorService {
    generateOutputFileNameFromConfig(config: TaskNameTokenConfig): string {
        return this.generateOutputFileName({
            taskName: config.hasTaskName ? 'taskName' : '',
            taskCategory: config.hasTaskCategory ? 'taskCategory' : '',
            dayType: config.dayType,
            isDateSelected: config.hasDateFormat,
            dateFormat: config.dateFormat,
            shiftNumber: config.shiftNumber,
            shiftType: config.shiftType,
            fileExtension: config.fileExtension
        });
    }

    generateOutputFileName({
        taskName,
        taskCategory,
        dateFormat,
        dayType,
        shiftType,
        shiftNumber,
        isDateSelected,
        fileExtension
    }: {
        taskName: string;
        taskCategory: string;
        dateFormat: string;
        dayType: string;
        shiftType: string;
        shiftNumber: number;
        isDateSelected: boolean;
        fileExtension?: string;
    }): string {
        let dateString = '';

        if (dayType === 'D' || dayType === 'B') {
            dateString = `{date,${dateFormat},${dayType}`;
            if (shiftType !== 'same' && shiftNumber !== 0) {
                dateString += `${shiftType}${shiftNumber}`;
            }
            dateString += '}';
        }

        const taskCategoryArray = [];

        if (taskName) {
            taskCategoryArray.push(`{${taskName}}`);
        }

        if (taskCategory) {
            taskCategoryArray.push(`{${taskCategory}}`);
        }

        const taskCategoryString = taskCategoryArray.join('_');

        let output: string;
        if (taskCategoryString && isDateSelected) {
            output = `${taskCategoryString}_${dateString}`;
        } else if (!taskCategoryString && isDateSelected) {
            output = dateString;
        } else {
            output = taskCategoryString;
        }
        if (!!fileExtension && fileExtension.length > 0) {
            const _fileExtension = fileExtension.charAt(0) === '.' ? fileExtension : `.${fileExtension}`;
            output += `{ext:${_fileExtension}}`;
        }
        return output;
    }

    parseInput(inputString: string) {
        // Group 0 -> match
        // Group 1 -> taskName
        // Group 2 -> taskCategory
        // Group 3 -> dateFormat section
        // Group 4 -> format
        // Group 5 -> day shift
        // Group 6 -> file extension
        const defaultDateFormat = 'dd/MM/yy';
        const defaults: TaskNameTokenConfig = {
            hasTaskName: false,
            hasTaskCategory: false,
            hasDateFormat: false,
            dateFormat: defaultDateFormat,
            dayType: 'D',
            shiftType: 'same',
            shiftNumber: 0
        };
        if (!this.areGroupsWrappedInCurlyBraces(inputString)) {
            return { ...defaults, isFileNameMatched: false };
        }
        const taskTokenMatcher =
            /(?:({taskName})_?)?(?:({taskCategory})_?)?({date,(.+),([DB](?:[+-](?:[1-9]|[12][0-9]|3[01]))?)})?(?:{ext:(\.[\w\d]+)})?$/;
        const _matches = inputString.match(taskTokenMatcher);
        const [match, taskName, taskCategory, dateFormatSection, dateFormat, dayShift, fileExtension] = _matches;
        if (!match) {
            // TODO: no match, use default?
            return { ...defaults, isFileNameMatched: false };
        }
        let tokenConfig: Partial<TaskNameTokenConfig> = {
            hasTaskName: !!taskName,
            hasTaskCategory: !!taskCategory,
            hasDateFormat: !!dateFormat,
            dateFormat: dateFormat || defaults.dateFormat,
            fileExtension,
            isFileNameMatched: true
        };
        if (dayShift) {
            const shiftMatcher = /([DB])(?:([+-])(\d\d?))?/;
            const shiftMatches = dayShift.match(shiftMatcher);
            const [match, _dayType, _shiftType, _shiftNumber] = shiftMatches;
            if (shiftMatches && shiftMatches.length === 4) {
                tokenConfig = {
                    ...tokenConfig,
                    dayType: _dayType,
                    shiftType: !_shiftType ? 'same' : _shiftType,
                    shiftNumber: _shiftNumber ? parseInt(_shiftNumber, 10) : 0
                };
            } else if (shiftMatches) {
                tokenConfig = {
                    ...tokenConfig,
                    dayType: _dayType
                };
            }
        }
        return {
            ...defaults,
            ...tokenConfig
        };
    }

    generateFileNamePreview({
        hasName,
        hasCategory,
        hasDate,
        dateFormat,
        name,
        category,
        fileExtension,
        useDatePlaceholder
    }: {
        hasName: boolean;
        hasCategory: boolean;
        hasDate: boolean;
        dateFormat: string;
        name: string;
        category: string;
        fileExtension?: string;
        useDatePlaceholder?: boolean;
    }) {
        let preview = '';

        if (hasName) {
            preview += `${name}`;
        }
        if (hasCategory) {
            preview += this.addSeparator(preview) + `${category}`;
        }
        if (hasDate && !useDatePlaceholder) {
            preview += this.addSeparator(preview) + `${this.formatDate(dateFormat)}`;
        } else if (hasDate && !!useDatePlaceholder) {
            preview += this.addSeparator(preview) + `{CHOSEN FORMATTED DATE WILL APPEAR HERE}`;
        }
        if (!!fileExtension && fileExtension.length > 0) {
            preview += fileExtension.charAt(0) === '.' ? fileExtension : `.${fileExtension}`;
        }
        return preview;
    }

    addSeparator(preview: string): string {
        return preview.length > 0 ? '_' : '';
    }

    private formatDate(dateFormat: string) {
        try {
            const formatValue = Date.now();
            if (!formatValue) {
                return null;
            }
            const formattedNow = format(new Date(), dateFormat);
            if (formattedNow && typeof formattedNow === 'string') {
                return formattedNow;
            }
            return null;
        } catch (e) {
            return null;
        }
    }

    areGroupsWrappedInCurlyBraces(fileName: string): boolean {
        const groups = fileName.split('_');

        for (const group of groups) {
            const match = group.match(/\{(.*?)\}/);

            if (!match) {
                return false;
            }
        }

        return true;
    }
}
